@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(../assets/BRUSHMOSH.ttf) format('truetype');
}
.home{
  margin: 0 !important;
  padding: 0 !important;
}

.scroll{
  font-family: Lato;
  font-size: 30px;
  color: aliceblue;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}
#textblock {
  background-color: #210002;
  height: 100vh;
}

#textblock-container {
  width: 50%;
  margin: 0 auto;
  padding-top: 70px;
}

#textblock-title {
  color: #ffaf1b;
  font-size: 35px;
  font-weight: 600;
  font-family: "Helvetica Neue";
}


#textblock-content {
  color: #ffaf1b;
  font-size: 20px;
}

#textblock-footer {
  color: #ffaf1b;
  font-size: 15px;
  font-weight: 400;
  position: fixed;
  width: 100%;
  bottom: 0px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

#textblock-devsense {
  text-decoration: none;
  color: #ffaf1b;
  font-size: 15px;
  font-weight: 600;
}

.animation,
.animation_layer {
  height: 100vh;
}

.animation {
  display: block;
  position: relative;
  z-index: 10;
}

.animation_layer {
  /* background-position: top center; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
}

.animation_layer.parallax {
  position: fixed;
}
/* .animation_layer_moon {
  background-position: bottom center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  background-position: cover;

  z-index: 1;
  top: -60px;
}
.animation_layer_moon.parallax {
  position: fixed;
} */
#background {
  background-image: url(../images/Background.png);
}
#Top_mount_side {
  background-image: url(../images/TopMountainSide.png);
}

#Mountain {
  background-image: url(../images/Mountain.png);
}

#Right_Side {
  background-image: url(../images/RightSide.png);
}

#firstpagemooon {
  background-image: url(../images/firstpagemoon.png);
}

#secondpageback {
  background-image: url(../images/SecondPageBackground.png);
}

#paper {
  background-image: url(../images/paper.png);
}

#thirdpagebackground {
  background-image: url(../images/ThirdPageBackground.png);
}

#rightgirl {
  background-image: url(../images/RightGirl.png);
}

#fourthpageback {
  background-image: url(../images/faourthpagebackground.png);
}

#fly {
  background-image: url(../images/fly.png);
}

/* kjashdkjhasdkjhaskjdh */
/* #kbackground {
  background-image: url(../images/kbackground.png);
}

#k1stback {
  background-image: url(../images/k1stback.png);
}

#k2ndback {
  background-image: url(../images/k2ndback.png);
}

#k3ndback {
  background-image: url(../images/k3ndback.png);
}

#k4ndback {
  background-image: url(../images/k4ndback.png);
}

#k5ndback {
  background-image: url(../images/k5ndback.png);
}

#kfly {
  background-image: url(../images/kfly.png);
} */

/* @media (min-width: 481px) {
  
} */

@media (max-width: 550px) {
  #background {
    /* background-color: #52514f; */
    background-image: url(../images/mobileBackground.png);
  }
  #Top_mount_side {
    /* background-color: #52514f; */
    background-image: url(../images/mobilecloud.png);
  }

  #Mountain {
    /* background-color: #52514f; */
    background-image: url(../images/mobilemountain.png);
  }
  #Right_Side {
    /* background-color: #52514f; */
    background-image: url(../images/mobilefirstpagerock.png);
  }
  #secondpageback {
    /* background-color: #52514f; */
    background-image: url(../images/mobilesecondpagebackground.png);
  }
  #paper {
    /* background-color: #52514f; */
    background-image: url(../images/mobilesecondpagegirl.png);
  }
  #thirdpagebackground {
    /* background-color: #52514f; */
    background-image: url(../images/mobilethirdpagebackground.png);
  }
  #rightgirl {
    /* background-color: #52514f; */
    background-image: url(../images/mobilethirdpagegirl.png);
  }
  #fourthpageback {
    /* background-color: #52514f; */
    background-image: url(../images/mobilefourthpagebackground.png);
  }
  #fly {
    /* background-color: #52514f; */
    background-image: url(../images/mobilefly.png);
  }
  #firstpagemooon {
    /* background-color: #52514f; */
    background-image: url(../images/mobileorb.png);
  }
  .animation,
  .animation_layer {
    height: 100%;
    width: 100%;
  }
  .animation {
    display: block;
    position: relative;
    z-index: 10;
  }
}
