.navbar {
  background: none !important;
  /* position: absolute !important; */
  margin: 0;
}
.nav {
  /* position: absolute !important; */
  top: 25% !important;
}
.container{
  background: transparent !important;
  width: 100% !important;
  padding: 0 !important;
}
.brand {
  height: 120px;
  width: 140px;
  background-color: #37323200 !important;
  border-radius: 10% !important;
}

#navIcon{
  height: 70px;
  width: 80px;
}

#hhhh{
  background-color: #37323200 !important;
}

img {
  height: 50px;
  width: 60px;
}
h6 {
  display: none;
}
Button {
  margin-left: 10px !important;
  background-color: #2b282800 !important;
  height: auto !important;
  border-radius: 0% !important;
  border: none !important;
  padding: 0 !important;
}
.home{
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.player{
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.home:hover{
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.player:hover{
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

Button:hover {
  background-color: rgba(22, 21, 21, 0.747) !important;
  border-radius: 10% !important;
}


/* .link:hover {
  color: rgb(31, 32, 34) !important;
} */

/* #musicon {
  background-image: url(../Navicons/musicon.png);
} */

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(../assets/BRUSHMOSH.ttf) format('truetype');
}



.scroll{
  font-family: Lato;
  font-size: 30px;
  color: aliceblue;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  
}


@media (max-width: 550px) {
  .land{
    margin: 0 !important;
  }
  .link {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    width: 50px !important;
    margin-bottom: 0 !important;
  }
  Button {
    /* border-radius: 10 !important; */
    background-color: #14131380 !important;
    /* margin-bottom: 0 !important; */
  }
  h6 {
    display: flex ;
    justify-content: center !important;
    align-items: center !important;
    /* text-align: center; */
    color: #f5f1f1;
    font-size: 25px !important;
    font-family: Lato;
    margin-bottom: 0 !important;
  }
  .nav {
    margin-top: 0 !important;
    /* border-radius: 20px !important; */
    
    
  }
  .navbar {
    
    padding: 0 !important;
  }
  .brand {
    height: 120px;
    width: 140px;
  }

  Button:hover{
    border-radius: 0px !important;
  }
  
  
}
