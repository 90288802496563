*{
    margin: 0;
    padding: 0;
}

.main-container{
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: none !important;
    
}

.main-container-mob{
    /* display: none; */
    visibility: hidden;
}




video{
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
.NAV{
    position: fixed;
    top: 0;
    width: 100%;
}

@media (max-width: 550px) {
    .main-container{
        /* display: none; */
        visibility: hidden;
    }
    .main-container-mob{
        visibility: visible;
        position: absolute;
        top: 0px;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        background-color: none !important;
    }
}